'use strict';
(function () {

  class BladeComponent {
    constructor(Auth, usSpinnerService, Blade, WorkOrder, $stateParams, $state, $uibModal, $scope, $rootScope) {
      this.Blade = Blade;
      this.WorkOrder = WorkOrder;
      this.$stateParams = $stateParams;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.$scope = $scope;
      this.$uibModal = $uibModal;
      this.usSpinnerService = usSpinnerService;
      this.isLoggedIn = Auth.isLoggedIn;
      this.blade = {};
      this.datePicker = { 
        date: { startDate: null, endDate: null },
        options: {
          ranges: {
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 14 Days': [moment().subtract(13, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()]
          }
        }
      };
      this.sharedLevel = localStorage.getItem('sharedLevel');
      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.init();
          this.currentUser = Auth.getCurrentUser();
        } else {
          $state.go('login');
        }
      });

      //broadcast to ListFindings.controller to refresh findings
      $scope.$on('addEditFindingCtrl', (event, args) => {
        $scope.$broadcast('bladeComponent', args);
        $rootScope.$emit('updateCounter', args);
      });

      $scope.$on('findingsReceived', (event, findings) => {
        // console.log('blade findings list: ', findings);
        this.bladeFindings = findings;
        let mostRecentActivityDate = new Date(this.blade.createdAt);
         _.each(findings, finding => {
          if(finding.repairs) {
            _.each(finding.repairs, repair => {
              if(repair.date && mostRecentActivityDate.getTime() < new Date(repair.date).getTime()) {
                mostRecentActivityDate = new Date(repair.date);
              } else if(!repair.date && mostRecentActivityDate.getTime() < new Date(repair.updatedAt).getTime()) {
                mostRecentActivityDate = new Date(repair.updatedAt);
              }
            });
          }
          if(finding.date && mostRecentActivityDate.getTime() < new Date(finding.date).getTime()) {
            mostRecentActivityDate = new Date(finding.date);
          } else if(!finding.date && mostRecentActivityDate.getTime() < new Date(finding.updatedAt).getTime()) {
            mostRecentActivityDate = new Date(finding.updatedAt);
          }
        });
        if(mostRecentActivityDate) {
          this.setMostRecentActivityDate(mostRecentActivityDate);
        }
      });
    }

    $onInit() {
    }

    getRepairs(blade) {
      this.WorkOrder.get({ id: 'blade-repair', controller: blade.bladeId }).$promise
        .then(response => {
          this.repairWOs = response.data;
          let mostRecentWO = _.maxBy(this.repairWOs, repairWO => repairWO.repair.date?new Date(repairWO.repair.date).getTime():0);
          if(mostRecentWO) {
            this.setMostRecentActivityDate(mostRecentWO.repair.date);
          }
        });
    }
    
    setMostRecentActivityDate(date) {
      if(this.siteOverview.completionDate.getTime() < new Date(date).getTime()) {
        this.siteOverview.completionDate = new Date(date);
      }
    }

    init() {
      this.loading = true;
      this.Blade.get({ id: this.$stateParams.id }).$promise
        .then(response => {
          if (response.data) {
            this.blade = response.data;
            // console.log('blade: ', this.blade);
            this.siteOverview = {
              reportNumber: Math.round(Date.now()/1000),
              siteName: this.blade.turbine.site.name,
              siteLocation: `${this.blade.turbine.site.location.addressLine3}, ${this.blade.turbine.site.location.addressLine4}, ${this.blade.turbine.site.location.country}`,
              sitePosition: this.blade.turbine.sitePosition,
              turbineSerialNo: this.blade.turbine.serial,
              turbineBlades: [this.blade],
              completionDate: new Date(this.blade.createdAt),
              turbineMake: this.blade.turbine.make,
              turbineModel: this.blade.turbine.model,
              reportDate: new Date()
            };
            this.getRepairs(this.blade);
          }
          this.loading = false;
          this.stopSpin('spinner-1');
        })
        .catch(err => {
          console.log(err);
          this.stopSpin('spinner-1');
          this.loading = false;
          this.blade = null;
        })
    }

    openAddRepairModal() {
      if(!this.bladeFindings) {
        console.log('no findings found in balde');
        return;
      }
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/repair/manage-repair-modal.html',
        controller: 'ManageRepairModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Add New Repair',
            blade: this.blade,
            findings: this.bladeFindings
          }
        }
      });
      modalInstance.result.then(result => {
        // console.log('add repair result: ', result);
        this.$scope.$broadcast('bladeComponent', { refresh: true });
      });
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    resetdaterange() {
      this.datePicker = { date: { startDate: null, endDate: null } };
    }
  }

  angular.module('windmanagerApp')
    .component('blade', {
      templateUrl: 'app/blade/blade.html',
      controller: BladeComponent,
      controllerAs: 'bc'
    });

})();
